/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('link-destroy-component', require('./components/LinkDestroyComponent.vue').default);



Vue.filter('dateBr', function (value) {
    let dateObj = new Date(value)
    return dateObj.toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // timeZone: 'UTC'
    }).replace(/\//g, '/')
})
Vue.filter('dateBrShort', function (value) {
    let dateObj = new Date(value)
    return dateObj.toLocaleString('pt-BR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        // timeZone: 'UTC'
    }).replace(/\//g, '/')
})

Vue.filter('datetimeBr', function (value) {
    let dateObj = new Date(value)
    return dateObj.toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        // timeZone: 'UTC'
    }).replace(/\//g, '/')
})

Vue.filter('real', function (value) {
    return new Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value)
})


const app = new Vue({
    el: '#app',
    methods: {
        setUrl(url){
            top.location.href = url;
        }
    }
});
